import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';
import { User } from '../models/user.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUser;
  private authStatusSub;
  constructor(public fireAuth: AngularFireAuth) {
    this.authStatusListener();
    this.authStatusSub = new BehaviorSubject(this.currentUser);
  }

  getAuthStateSub() {
    return this.authStatusSub;
  }

  authStatusListener() {
    this.fireAuth.onAuthStateChanged((credential) => {
      if (credential) {
        const campaign = new User({
          _id: credential.uid,
          email: credential.email,
          name: credential.providerData[0]?.displayName ?? '',
        });
        campaign.save()
        .catch(e=>console.log('Error usuario: ', e))
        .finally(()=>{
          this.authStatusSub.next(credential);
          console.log('User is logged in');
        });
      }
      else {
        this.authStatusSub.next(null);
        console.log('User is logged out');
      }
    });
  }

  signIn() {
    return this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider())
      .then((res: any) => {
        if (res) {
          console.log(res);
        }
      });
  }

  signOut() {
    return this.fireAuth.signOut();
  }

}
