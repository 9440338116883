/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable new-parens */
/* eslint-disable no-underscore-dangle */
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';

export abstract class FireModel {
  static wpAccount: any;
  static db: AngularFirestore;
  static user: any;
  private _store = '';

  constructor(_store, data = null) {
    this._store = _store;
    if (data) {
      this.fromJSON(data);
    }
    //const wpAccount = appController.getWpAccount();
  }

  static getCurrentDate() {
    //return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 1000 * 60).toISOString();
    return new Date().toISOString();
  }

  //Serialize methods
  fromJSON(input: any): this {
    if (input) {
      return Object.assign(this, input);
    } else {
      return null;
    }
  }

  toJSON() {
    const data: any = {};
    const keys = Object.keys(this).filter((k) => k[0] !== '_');
    for (const key of keys) {
      data[key] = this[key];
    }
    return data;
  }

  getPath(): string {
    return `wapp/${FireModel.user.uid}/accounts/${FireModel.wpAccount.wid}/${this._store}`;
  }

  async getAll(order?: any) {
    const path = this.getPath();
    //console.log(path);

    return FireModel.db
      .collection(path, (ref) => {
        let data: any = ref;
        if (order) {
          for (const i of Object.keys(order)) {
            data = data.orderBy(i, order[i]);
          }
        }
        return data;
      })
      .get()
      .toPromise()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const data = new (this.constructor as any)().fromJSON(doc.data());
          data._id = doc.id;
          return data;
        })
      );
  }

  async getByKey(key) {
    const path = this.getPath();
    const doc = await FireModel.db.firestore.collection(path).doc(key).get();
    const data = new (this.constructor as any)().fromJSON(doc.data());
    data._id = doc.id;
    return data;
  }

  getChanges(idx, value, order, limit) {
    const path = this.getPath();
    const subject = new Subject<{ data: any; type: string }>();
    let tmp;
    if (idx && value && order) {
      tmp = FireModel.db.firestore
        .collection(path)
        .where(idx, '==', value)
        .orderBy(order)
        .limit(limit);
    } else {
      tmp = FireModel.db.firestore.collection(path).limit(limit);
    }

    const unsuscribe = tmp.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const data = new (this.constructor as any)().fromJSON(
          change.doc.data()
        );
        data._id = change.doc.id;
        subject.next({ data, type: change.type });
      });
    });
    return { unsuscribe, subject };
  }

  getDocumentChanges(id) {
    const path = this.getPath();
    const subscriptionRef = FireModel.db.doc(`${path}/${id}`);
    return subscriptionRef.valueChanges();
  }

  async save() {
    const path = this.getPath();
    let data;
    if (!this['_id']) {
      data = await FireModel.db.collection(path).add(this.toJSON());
    } else {
      data = await FireModel.db
        .collection(path)
        .doc(this['_id'])
        .set(this.toJSON());
    }
    return data;
    //return this;
  }

  async saveAll(models: any[]) {
    const batch = FireModel.db.firestore.batch();
    const path = this.getPath();
    const collection = FireModel.db.firestore.collection(path);
    models.forEach((data) => {
      const docId = data._id ? data._id : collection.doc().id; // Usar _id si existe, de lo contrario generar uno
      const key = collection.doc(docId); // Usar el Document ID generado o proporcionado
      batch.set(key, data);
    });
    // Save
    await batch.commit();
  }

  async setStatusCampaign(campaignId: string, status: number) {
    const path = this.getPath();
    const querySnapshot = await FireModel.db.firestore
      .collection(path)
      .doc(campaignId)
      .get();
    const docRef = querySnapshot.ref;
    await docRef.update({ status: status });
  }

  getByIndex(idx, value, order, limit) {
    const path = this.getPath();
    return FireModel.db.firestore
      .collection(path)
      .where(idx, '==', value)
      .orderBy(order)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const data = new (this.constructor as any)().fromJSON(doc.data());
          data._id = doc.id;
          return data;
        }
        return null;
      });
  }
}
