/* eslint-disable new-parens */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { FireModel } from './fire-model.module';
import { Message } from './message.model';

export class MessageGroup extends FireModel{
    _id: string;
    _campaignId: string;
    numGroup: number;
    nextRow: number;
    rows: Message[];
    status: number;
    date: string;

    constructor(data=null){
        super('messages', data);
        if(!this.date){
            this.date = MessageGroup.getCurrentDate();
        }
        this.rows = [];
    }

    getPath(): string {
        return super.getPath().replace('/messages', `/campaigns/${this._campaignId}/messages`);
    }
    getByCampaignIdAndStatus(campaignId: string, status: number){
        this._campaignId = campaignId;
        const path = this.getPath();
        return FireModel.db.firestore.collection(path).where('status', '==', status).orderBy('numGroup').limit(1)
            .get()
            .then(querySnapshot=>{
                if(!querySnapshot.empty){
                    const doc = querySnapshot.docs[0];
                    const data = (new (this.constructor as any)).fromJSON(doc.data());
                    data._id = doc.id;
                    data._campaignId = campaignId;
                    return data;
                }
                return null;
            });
    }

    getOutdatedState(campaignId: string){
        this._campaignId = campaignId;
        const path = this.getPath();
        return FireModel.db.firestore.collection(path).where('status', '<', Message.STATUS_READ)
            .get()
            .then(querySnapshot=>{
                if(!querySnapshot.empty){
                    return querySnapshot.docs.map(doc=>{
                        const data = (new (this.constructor as any)).fromJSON(doc.data());
                        data._id = doc.id;
                        data._campaignId = campaignId;
                        return data;
                    });
                }
                return [];
            });
    }

    fromJSON(group: any){
        group = super.fromJSON(group);
        if(!this.rows){
            this.rows = [];
        }
        group.rows = this.rows.map(r=>new Message().fromJSON(r));
        return group;
    }

    toJSON() {
        const data: any = super.toJSON();
        if(!data.rows){
            data.rows = [];
        }
        data.rows = data.rows.map((m)=> m.toJSON() );
        return data;
      }

    /*
    getOutdatedState(){
        return Message.dbService.getAllByIndex(this.store, 'message_status',
            IDBKeyRange.bound(Message.STATUS_SENT, Message.STATUS_RECEIVED, false, false))
            .toPromise()
            .then((messages: any[])=>{
                const status = {};
                for(const message of messages){
                    status[message.wp_id] = message.status-Message.STATUS_SENT+1;
                }
                return status;
            });
    }
    */
}
