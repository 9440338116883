import { Component } from '@angular/core';
import { Settings } from './models/settings.model';
import { AppControllerService } from './services/app-controller.service';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Campañas', url: '/campaigns', icon: 'logo-whatsapp' },
    { title: 'Configuración', url: '/settings', icon: 'settings-outline' },
  ];
  wpAccount: any;
  user: any = null;
  subscription: any = null;

  constructor(private appController: AppControllerService, private authservice: AuthService) {
    appController.wpAccountS.subscribe(wpAccount => this.wpAccount = wpAccount);
    this.authservice.getAuthStateSub()
      .subscribe(user => this.user = user );

    appController.subscriptionS.subscribe(s=>this.subscription = s);

    //Loading settings
    /*
    new Settings().count().subscribe((numSettings) => {
      if (numSettings === 0) {
        Settings.getDefault().save().subscribe(() => {
          console.log('Configuración creada');
        });
      }
    });*/
  }

  getPhone(wid){
    return wid.split('@')[0];
  }

  openInfo(codError){
    let message;
    this.appController.updateAccount();
    if(codError === 1){
      message = 'Por favor revise que WhatsApp Web esté abierto en el navegador y conectado a su teléfono.';
    } else {
      message = 'Por favor revise que su teléfono esté conectado a internet.';
    }

    this.appController.showError(message);
  }

  logout(){
    this.authservice.signOut();
  }
}
