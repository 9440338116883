/* eslint-disable @typescript-eslint/naming-convention */
import { FireModel } from './fire-model.module';

export class Subscription extends FireModel{
    _id: string;
    consumed_credits: number;
    creation: number;
    credits: number;
    expiration: number;
    limit: number;
    plan: number;

    constructor(data=null){
        super('subscription', data);
    }

    getPath(): string{
        return `wapp-subscriptions`;
    }
}
