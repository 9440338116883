import { FireModel } from './fire-model.module';

export class Settings extends FireModel{
    _id: number;
    country: string;
    delay: number;
    simulateWriting: string;

    constructor(data=null){
        super('settings', data);
    }

    static getCurrent() {
        return new Settings().getAll()
            .then(allSettings => {
                let settings;
                if (allSettings.length > 0) {
                    settings = allSettings[0];
                } else {
                    settings = Settings.getDefault();
                }
                return settings;
            });
    }

    static getDefault() {
        return new Settings({
            country: '',
            delay: 5,
            simulateWriting: '1'
        });
    }
}
