/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */

import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
import { Campaign } from '../models/campaign.model';
import { Message } from '../models/message.model';
import { FireModel } from '../models/fire-model.module';
import { Settings } from '../models/settings.model';
import { WhatsappApiService } from './whatsapp-api.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { MessageGroup } from '../models/message-group.model';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Subscription } from '../models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class AppControllerService {
  loading: any = null;
  private _platform: string = null;
  private _currentCampaign: Subject<Campaign>;
  private lastCampaign: Campaign;
  private _wpAccountS: Subject<any>;
  private wpAccount: any;
  private lastMessageGroup: any;
  private _campaignSU = null;
  private _settingsSU = null;
  private _subscriptionsSU = null;
  private _settings: Settings;
  private _subscriptionS: Subject<any>;
  private _subscription: any;
  private lastMessageId = null;

  constructor(public loadingController: LoadingController, private alertController: AlertController,
    private dbService: NgxIndexedDBService, private whatsApp: WhatsappApiService, private db: AngularFirestore,
    private authservice: AuthService, private storage: AngularFireStorage) {
    //db.firestore.useEmulator('localhost', 8080);
    FireModel.db = db;
    this._currentCampaign = new Subject();
    this._wpAccountS = new Subject();
    this._subscriptionS = new Subject();
    let actived = true;

    this.whatsApp.enabled.subscribe(enabled => {
      if (enabled) {
        this.isConnected();
      }
    });

    this.authservice.getAuthStateSub()
      .subscribe(user => FireModel.user = user);

    this._wpAccountS.subscribe(wpAccountS=>{
      if(this.wpAccount !== wpAccountS){
        if(this._campaignSU){
          this._campaignSU();
        }
        if(this._settingsSU){
          this._settingsSU();
        }
        if(this._subscriptionsSU){
          this._subscriptionsSU.unsubscribe();
          this._subscriptionS.next(null);
          this._subscription = null;
        }
        this._campaignSU = null;
        this._settingsSU = null;
        this._subscriptionsSU = null;
        this.lastCampaign = null;
      }
    });

    const interval = setInterval(() => {
      if(!this._campaignSU && this.wpAccount && FireModel.user){
        const changesS = new Campaign().getChanges('status', Campaign.STATUS_SENT, 'date', 1);
        this._campaignSU = changesS.unsuscribe;
        changesS.subject.subscribe(changes => {
          if(changes.type === 'added'){
            this.lastCampaign = changes.data;
          } else if(changes.type === 'removed'){
            if(this.lastCampaign && this.lastCampaign._id === changes.data._id){
              this.lastCampaign = null;
            }
          }
        });
        const changesSS = new Settings().getChanges(null, null, null, 1);
        this._settingsSU = changesSS.unsuscribe;
        changesSS.subject.subscribe(changes => {
          this._settings = changes.data;
          //console.log('settings', this._settings);
        });
        this._subscriptionsSU = new Subscription().getDocumentChanges(FireModel.user.uid).subscribe(subscription => {
          this._subscriptionS.next(subscription);
          this._subscription = subscription;
        });
      }

      if (this._settings && actived && this.lastCampaign) {
        actived = false;
        this.runLoop()
        .catch(e=>console.log(e))
        .finally(() => setTimeout(() => actived = true, this._settings.delay * 1000));
      }
    }, 500);

    //Verificar cada 30 segundos si sigue conectado a WhatsApp
    const interval2 = setInterval(() => {
      this.isConnected();
    }, 30 * 1000);
  }

  isConnected() {
    return this.updateAccount()
      .then(_ => (this.wpAccount && this.wpAccount.connected))
      .catch(e => false);
  }

  //Updating wpAccount status
  async updateAccount(){
    try {
      this.wpAccount = await this.whatsApp.getMe();
      FireModel.wpAccount = this.wpAccount;
      this._wpAccountS.next(this.wpAccount);
    } catch(e){
      //console.log(e.message);
      throw new Error('Error de consulta');
    }
  }

  //Sending messages
  runLoop() {
    if(!this.wpAccount){
      return Promise.reject(new Error('Error de conexión a WhatsApp'));
    }

    const finish = () => {
      this.lastCampaign.nextRow = this.lastCampaign.numRows+1;
          this.lastCampaign.status = Campaign.STATUS_FINISHED;
          this.lastCampaign.save()
            .then(()=>{
              this._currentCampaign.next(this.lastCampaign);
              this.lastCampaign = null;
              return null;
            });
    };

    return this.procMessage()
      .then(messageGroup => {
        if(!this.lastCampaign.nextRow){
          this.lastCampaign.nextRow = 1;
        }
        if(!messageGroup){
          finish();
        } else {
          this.lastCampaign.nextRow = (messageGroup.numGroup-1)*Campaign.LIMIT+messageGroup.nextRow;
        }
        if(this.lastCampaign.nextRow > this.lastCampaign.numRows){
          finish();
        }
      })
      .then(() => this._currentCampaign.next(this.lastCampaign));
  }

  async procMessage(){
    const campaign = this.lastCampaign;
    let lastMessageGroup = this.lastMessageGroup;
    //TODO: receiving external updates from object
    if(!lastMessageGroup){
      lastMessageGroup = await new MessageGroup().getByCampaignIdAndStatus(campaign._id, Message.STATUS_CREATED);
      if(lastMessageGroup){
        this.lastCampaign.nextRow = (lastMessageGroup.numGroup-1)*Campaign.LIMIT+lastMessageGroup.nextRow;
        this._currentCampaign.next(this.lastCampaign);
      } else {
        return null;
      }
    }

    if(lastMessageGroup && lastMessageGroup.nextRow <= lastMessageGroup.rows.length){
      const message = lastMessageGroup.rows[lastMessageGroup.nextRow-1];
      const lastMessageId = this.lastMessageId;
      this.lastMessageId = `${campaign._id}_${lastMessageGroup.numGroup}_${lastMessageGroup.nextRow}`;
      //console.log('ids', lastMessageId, this.lastMessageId);
      //console.log('message:', message);
      try {
        await this.sendMessage(campaign, message, campaign.messages);
      } catch (error){
        //console.log('error:', error);
        if (typeof error == 'object'){
          if('code' in error){
            switch(error.code){
              case 'CONNECTION_ERROR':
                // Reintentar cuando no hay conexión con whatsapp web
                return lastMessageGroup;
              case 'CONTACT_ERROR':
                // No reenviar
                console.log('contact error:', message);
              break;
              case 'FILE_SEND_ERROR':
                // Guardar datos de envío parcial
                message.wp_id = error.data?.wp_id;
                message.t = error.data?.t;
                message.date = MessageGroup.getCurrentDate();
                error = error.error?.stack ?? error.error?.toString();
              break;
            }
          }
        } else if(error === 'Error de conexión WhatsApp'){
          return lastMessageGroup;
        } else if(error === 'Timeout error' && lastMessageId !== this.lastMessageId){
          console.log('Time out reintentar 1 vez');
          return lastMessageGroup;
        }

        message.error = error;
        message.status = Message.STATUS_ERROR;
        message.date = MessageGroup.getCurrentDate();
      }

      lastMessageGroup.nextRow +=1;
      if(lastMessageGroup.nextRow > lastMessageGroup.rows.length){
        lastMessageGroup.nextRow = lastMessageGroup.rows.length+1;
        lastMessageGroup.status = Message.STATUS_SENT;
        this.lastMessageGroup = null;
      }

      await lastMessageGroup.save();
    }
   return lastMessageGroup;
  }

  public async sendMessage(campaign, message, messages, test = false){
    const phone = message.phone;
    let id = `${campaign._id}-${phone}`;
    if(test === true){
      id = `${id}-${Math.random()}`;
    }
    messages = [...messages];

    if (message) {
      for (const field of campaign.fields) {
        messages[0] = messages[0].replace(new RegExp(`{${field}}`, 'gi'), message.data[field]);
      }
    }
    const data = await this.whatsApp.sendWPMessage(id, this._settings.country, phone, messages);
    if(!data || !('wp_id' in data)){
      throw new Error('Data error');
    }
    //console.log('sendMessage:', data);
    message.status = Message.STATUS_SENT;
    message.wp_id = data.wp_id;
    message.t = data.t;
    message.date = MessageGroup.getCurrentDate();
    return message;
  }

  public uploadFile(filename: string, file: File){
    filename = `wapp/files/${FireModel.user.uid}/${filename}`;
    const filenameArr = file.name.split('.');
    if(filenameArr.length > 1){
      filename += '.'+filenameArr[filenameArr.length-1];
    }

    const fileRef = this.storage.ref(filename);
    const task = this.storage.upload(filename, file);

    //Cambia el porcentaje
    /*
    task.percentageChanges().subscribe((porcentaje) => {
      const t_porcentaje = Math.round(porcentaje);
      console.log('porcentaje:', porcentaje);
      if (t_porcentaje === 100) {
        //this.finalizado = true;
        console.log('finalizado');
      }
    });
    */
    return new Promise ((resolve, reject) => {
      task.catch(e=>reject(e));
      task.snapshotChanges().subscribe(e=>{
        if(e.state === 'success'){
          fileRef.getDownloadURL().subscribe((url) => {
            resolve(url);
          });
        }
      });
    });
  }

  _loadFileToBase64(file: File): Promise<string>{
    return new Promise ((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          if(reader.result !== null){
            resolve(reader.result.toString().split(',')[1]);
          }
      };
      reader.onerror = e=> {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  }

  public async uploadFileBase64(filename: string, file: File){
    filename = `wapp/files/${FireModel.user.uid}/base64/${filename}`;
    const fileRef = this.storage.ref(filename);
    const content = await this._loadFileToBase64(file);
    const task = fileRef.putString(content);

    return new Promise ((resolve, reject) => {
      task.catch(e=>reject(e));
      task.snapshotChanges().subscribe(e=>{
        if(e.state === 'success'){
          fileRef.getDownloadURL().subscribe((url) => {
            resolve(url);
          });
        }
      });
    });
  }

  public get wpAccountS() {
    return this._wpAccountS;
  }

  public get subscriptionS() {
    return this._subscriptionS;
  }

  public get subscription() {
    return this._subscription;
  }

  public get currentCampaign() {
    return this._currentCampaign;
  }

  public get platform(): string {
    return this._platform;
  }
  public set platform(value: string) {
    this._platform = value;
  }

  public getWpStatus(ids): Promise<any>{
    return this.whatsApp.getStatus(ids);
  }

  public getWpAccount(): any{
    return this.wpAccount;
  }

  public getDb(): any{
    return this.db;
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: 'Por favor espere...'
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  async showError(message: any, title='ERROR') {
    if((typeof message) == 'object' && 'text' in message){
      message = message.text;
    }

    const alert = await this.alertController.create({
      header: title,
      message,
      buttons: [
        {
          text: 'ACEPTAR',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }

  showConfirm(message: any, title = 'ALERTA'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (typeof message == 'object' && 'text' in message) {
        message = message.text;
      }

      const alert = this.alertController.create({
        header: title,
        message,
        buttons: [
          {
            text: 'CANCELAR',
            role: 'cancel',
            handler: () => {
              reject('Se ha cancelado la operación');
            },
          },
          {
            text: 'ACEPTAR',
            role: 'confirm',
            handler: () => {
              resolve();
            },
          },
        ],
      });

      alert
        .then((alertEl) => {
          alertEl.present();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
