import { FireModel } from './fire-model.module';

export class User extends FireModel{
    _id: string;
    uid: string;
    email: string;
    name: string;
    date: string;

    constructor(data=null){
        super('user', data);
        if(!this.date){
            this.date = User.getCurrentDate();
        }
    }

    getPath(): string {
        return 'wapp';
    }
}
